import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }

  body {
    background: #fefaf5;
    color: #323232;
    margin: 0;
    font-size: 1.4rem;
    font-family: "canada-type-gibson", sans-serif;

    @media only screen and (min-width: 1024px) {
      font-size: 1.6rem;
    }
  }

  address {
    font-style: normal;
  }

  /* h1,h2,h3,h4,h5,h6,.heading__title {
    font-family: "Cormorant Garamond", serif;
  }
  */

  h1,h2,h3,h4,h5,h6 {
    span {
      display: block;

      @media only screen and (min-width: 1024px) {
        display: inline-block;
      }
    }
  }

  @keyframes slidein {
    0% {
     transform: translateX(-100%);
    }

    100% {
     transform: translateX(0);
    }
  }

  h1 {
    padding: 0 0 15px;
    font-size: 3.2rem;
    margin: .5rem 0 3.5rem;
    overflow: hidden;
    position: relative;

    @media only screen and (min-width: 1024px) {
      font-size: 4.2rem;
    }
  }

  h2 {
    font-size: 3.9rem;
    font-weight: 500;
    line-height: 90%;
    letter-spacing: -0.78px;
    /* margin: 0 0 16px; */
    margin: 0 0 24px;

    @media only screen and (min-width: 1024px) {
      font-size: 6.1rem;
      margin: 0 0 24px;
    }
  }

  h3 {
    font-size: 3.4rem;
    font-weight: 400;
    margin: 0 0 24px;

    @media only screen and (min-width: 1024px) {
      font-size: 3.4rem;
    }

    @media only screen and (min-width: 1400px) {
      font-size: 5.2rem;
    }
  }

  h4 {
    font-size: 2.4rem;
    font-weight: 400;
    margin: 0 0 24px;
  }

  p {
    line-height: 140%;
  }

  .lead {
    font-size: 1.8rem;
    line-height: 130%;

    @media only screen and (min-width: 1024px) {
      font-size: 2.2rem;
      margin: 0 0 24px;
    }

    @media only screen and (min-width: 1400px) {
      margin: 0 0 32px;
    }
  }

  a {
    color: #323232;
    transition: color 150ms ease-in;
    /* color: hsl(347,84%,48%); */
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }
  }

  address, dl {
    line-height: 150%;
  }
  
  img {
    max-width: 100%;
  }

  button {
    cursor: pointer;
  }

  main {
    margin: 0 auto 100px;
    min-height: 330px;
  }

  .row {
    padding: 0 24px;
    margin-inline: auto;
    width: min(100%, 1140px);
  }

  .text-center {
    text-align: center;
  }

  .no-marg {
    margin: 0;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .btn {
    transition: all 150ms ease-in;
    background: #78569D;
    border: 0;
    border-radius: 25px;
    color: #FEFAF5;
    display: inline-block;
    font-size: 1.6rem;
    padding: 15px 24px;
    text-align: center;
    width: 100%;

    @media only screen and (min-width: 480px) {
      max-width: 170px;
    }

    @media only screen and (min-width: 960px) {
      /* max-width: none;
      width: auto; */
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:hover {
      background: #503969;
      color: #fff;
    }

    &:focus {
      box-shadow: 0 0 4px 0px #699F8C;

    }

    &[disabled] {
      background: #BDB5AA;
      pointer-events: none;
    }

    &--auto {
      width: max-content;
      max-width: none;
    }
  }

  .btn--send {
    background: hsl(347, 84%, 48%);
    border: 2px solid hsl(347, 84%, 48%);
    color: #fff;

    &:hover,
    &:focus {
      background: #fff;
      color: hsl(347, 84%, 48%);
      text-decoration: none;

      svg {
        path {
          fill: hsl(347, 84%, 48%);
        }
      }
    }

    svg {
      path {
        transition: fill 200ms ease-in;
        fill: #fff;
      }
    }
  }

  .disabled {
    opacity: .25;
    pointer-events: none;
  }

  .columns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 56px;

    @media only screen and (min-width: 960px) {
      align-items: center;

      .col--6 {
        width: 50%;
      }

      .col--invert {
        order: 1;
      }
    }
  }

  @media only screen and (min-width: 480px) {
    .only-mobile {
      display: none;
    }
  }

  @media only screen and (max-width: 479px) {
    .only-desktop {
      display: none;
    }
  }
`;
