module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#78569D","description":"Kosmetyki i suplementy dla psów i kotów","display":"fullscreen","dir":"ltr","icon":"src/assets/images/favicon.png","icons":[{"src":"/favicons/homescreen32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/homescreen48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/homescreen72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/homescreen96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/homescreen144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/homescreen168.png","sizes":"168x168","type":"image/png"},{"src":"/favicons/homescreen192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/splash-screen.png","sizes":"600x315","type":"image/jpg"}],"lang":"pl","name":"Fair Fur","orientation":"portrait","short_name":"Fair Fur","start_url":"/","theme_color":"#200b1d","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a416d67d198ecdba4d40e4687a9be744"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
